import * as Sentry from "@sentry/nuxt";
import type {
  BrowserClientProfilingOptions,
  BrowserClientReplayOptions,
  ClientOptions,
} from "@sentry/types";
import { useRuntimeConfig } from "#imports";

const sentryConfig = useRuntimeConfig().public.sentry;

const options: Omit<ClientOptions, "transport" | "stackParser"> &
  BrowserClientProfilingOptions &
  BrowserClientReplayOptions = {
  environment: sentryConfig.environment,
  release: sentryConfig.release,
  enabled: sentryConfig.enabled === "true",
  // DSNs are safe to keep public because they only allow submission of new events and related event data,
  // they do not allow read access to any information. (https://docs.sentry.io/concepts/key-terms/dsn-explainer/#dsn-utilization)
  dsn: sentryConfig.dsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [],
  replaysSessionSampleRate: sentryConfig.environment === "prod" ? 0 : 1.0,
  replaysOnErrorSampleRate: 1.0,

  beforeSend(event) {
    if (event.user) {
      // Strip sensitive data from user context that might be present
      // We only add the hashed customer id to the sentry context in middleware/userData.ts
      // so that we can map errors to the same customer in a sentry session
      delete event.user.ip_address;
      delete event.user.geo;
      delete event.user.sentry_user;
    }
    return event;
  },
};

Sentry.init(options);
